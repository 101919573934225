import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import fb from '../assets/Facebook_Icon.png'
import twitter from '../assets/Twitter_Icon.png'
import insta from '../assets/Instagram_Icon.png'
import logo from '../assets/Rehbar_logo.png'
import './footer.css'

class Footer extends Component {
    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
    render() {
        return (
            <div className='foot'>
                <div className="rehbar_logo mr-auto footer_logo">
                    <Link to='/'><img src={logo} alt='logo' /></Link>
                </div>

                <div className="foot_link_div">
                    <div onClick={() => this.topFunction()} className='footer_links'>
                        <Link to='/about-us'><div>About</div></Link>
                        <Link to='/invest'><div>Invest</div></Link>
                        <Link to='/get_funded'><div>Get Funded</div></Link>
                        <Link to='/faq'><div>FAQ</div></Link>
                        <Link to='/products_and_services'><div>Products & Services</div></Link>
                        <Link to='/join_us'><div>Join Us</div></Link>
                        <a target="_blank" href='https://rehbar-finance.tumblr.com/' rel="noopener noreferrer"><div>Blog</div></a>
                        <Link to='/contact'><div>Contact</div></Link>
                        <Link to='/about-us/foundation'><div>Rehbar Foundation</div></Link>
                    </div>
                    <div className='social_media'>
                        <div><a rel="noopener noreferrer" target="_blank" href='https://www.facebook.com/RehbarFinancialServices'><img src={fb} alt='social media' /></a></div>
                        <div><a rel="noopener noreferrer" target="_blank" href='https://twitter.com/Rehbar_Fin'><img src={twitter} alt='social media' /></a></div>
                        <div><a rel="noopener noreferrer" target="_blank" href='https://www.instagram.com/rehbar_fin/'><img src={insta} alt='social media' /></a></div>
                    </div>
                </div>
            </div>

        )


    }

}

export default Footer;





