import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Axios from 'axios';
import cachios from 'cachios'

import { BASEURL, BASE } from "../api/api";
import { Button, Form, FormGroup, Input } from 'reactstrap';
import Slider from "react-slick";
import Header from '../header/header'
import Footer from '../footer/footer'
import './getfunded.css'
import gf1 from '../assets/GF 1.png'
import gf2 from '../assets/GF 2.png'
import quote from '../assets/quote-watermark.png'
import dummy from '../assets/dummyimg.jpg'
import attach from '../assets/attach.png'
import activeleft from '../assets/Active left.png'
import deactiveleft from '../assets/De active left.png'
import activeright from '../assets/active right.png'
import deactiveright from '../assets/De active right.png'
import $ from "jquery";
import white from '../assets/white.png'
import loader from '../assets/loading.gif'
import MagicSliderDots from 'react-magic-slider-dots';
import Mail from '../sitemap/mailchimp';
import Recaptcha from 'react-recaptcha'


let imagearray2 = []
let logoarray2 = []
let filepath = null;

class Funded extends Component {
    constructor(props) {
        super(props);
        this.callback = this.callback.bind(this)
        this.loaded = this.loaded.bind(this)
        this.state = {
            name: '',
            company: '',
            number: '',
            email: '',
            amount: '',
            purpose: '',
            industry: '',
            years: '',
            upname: '',
            upphone: '',
            upmail: '',
            upmsg: '',
            popup: false,
            title: '',
            whatwe: '',
            testimonial: '',
            testlen: '',
            testindex: 0,
            testlogo: false,
            testimg: false,
            sliderprop: '',
            mailchimp: '',
            formex: true,
            width: window.innerWidth,
            filepath: '',
            filestatus: true,
            disable: false,
            uploadtxt: 'File Upload',
            formvalid: false,
            formvalidtext: 'Please enter valid details',
            formsuccess: false,
            formsubmit: false,
            form1: false,
            form2: false, uplocation: '',
            cc_code: 91,
            btntext: 'Send'
        }

    }



    popup() {

        document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
        document.getElementById("upspan1").style.color = '#828080';
        document.getElementById("upspan1").style.cursor = 'pointer';
        this.setState({
            upmsg: '',
            upmail: '',
            upname: '',
            upphone: '',
            uploadtxt: 'File Upload',
            uplocation: '',
            formsubmit: false,
            pophum: false
        })
        this.setState({
            popup: true
        })
        this.clearforms()

    }

    close() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
        document.getElementById("upspan").style.color = '#828080';
        document.getElementById("upspan").style.cursor = 'pointer';
        this.setState({
            popup: false,
            upmsg: '',
            upmail: '',
            upname: '',
            upphone: '',
            uplocation: '',
            pophum: false,
            uploadtxt: 'File Upload',
            formsubmit: false,
            pophum: false
        })
        this.clearforms()

    }

    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })

    }

    testindex(id) {

        if (id === 1) {
            if (this.state.testindex !== 0) {
                this.setState({
                    testindex: this.state.testindex - 1
                })

            }
            else {
                this.setState({
                    testindex: 0
                })
            }

        }


        else {
            if (this.state.testlen - 1 !== this.state.testindex) {
                this.setState({
                    testindex: this.state.testindex + 1
                })
            }
            else {
                this.setState({
                    testindex: this.state.testlen - 1
                })
            }
        }
    }

    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
    scrollFn() {
        document.body.scrollTop = 500; // For Safari
        document.documentElement.scrollTop = 500; // For Chrome, Firefox, IE and Opera
    }




    //upload
    fileupload(e, id, upspan) {
        if ($(id).prop('files')[0] !== null) {
            var file_data = $(id).prop('files')[0];
            var form_data = new FormData();
            form_data.append('file', file_data);
            $.ajax({
                url: BASEURL + '/upload.php', // point to server-side PHP script 
                dataType: 'text',  // what to expect back from the PHP script, if anything
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                type: 'post',
                success: function (image_url) {
                    console.log('uploaded')
                    filepath = image_url
                }

            });



            this.setState({
                uploadtxt: 'File Uploaded'
            })
        }



    }
    upload() {

        this.setState({ formsubmit: true, form2: true });
        setTimeout(
            function () {
                this.setState({ formsubmit: false, form2: false });
            }
                .bind(this),
            10000
        );
        if (this.state.upname) {

            if (this.state.upphone.length === 10) {
                if (this.state.uplocation) {

                    if (this.state.upmail) {
                        if (this.validateEmail(this.state.upmail)) {
                            if (this.state.upmsg) {
                                if (this.state.pophum) {


                                    // if (filepath !== null) {

                                    this.setState({
                                        btntext: 'Sending....'
                                    })
                                    const formbody =
                                    {
                                        "contact_form": [{ "target_id": "enquiry_form" }],
                                        "field_phone": [{ "value": "+" + this.state.cc_code + "-" + this.state.upphone }],
                                        "field_location": [{ "value": this.state.uplocation }],
                                        "message": [{ "value": this.state.upmsg }],
                                        "field_email": [{ "value": this.state.upmail }],
                                        "subject": [{ "value": "Form" }],
                                        "field_file_link": [{ "value": filepath ? filepath : null }],
                                        "field_name": [{ "value": this.state.upname }],
                                    }

                                    console.log(formbody)
                                    Axios(BASEURL + '/entity/contact_message?_format=json', {

                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        data: formbody
                                    })
                                        .then((res) => {
                                            this.setState({
                                                uploadtxt: 'File Upload'
                                            })


                                            document.getElementsByTagName("BODY")[0].style.overflow = "auto";


                                            filepath = null;


                                            this.setState({
                                                upmsg: '',
                                                upmail: '',
                                                uplocation: '',
                                                upname: '',
                                                upphone: '',
                                                uploadtxt: 'File Upload',
                                                formvalid: true,
                                                formsuccess: true,
                                                formvalidtext: 'Thank you!Your Submission has been received',
                                                formsubmit: false,
                                                form2: true,
                                                popup: false,
                                                btntext: 'Send',
                                                pophum: false
                                            })
                                            this.clearforms()

                                            setTimeout(
                                                function () {
                                                    this.setState({ formvalid: false, form2: false });
                                                }
                                                    .bind(this),
                                                3000
                                            );
                                        })
                                        .catch((err) => {
                                            console.log(err, 'formerror')
                                            this.setState({
                                                formvalid: true,
                                                btntext: 'Send',
                                                formvalidtext: 'Server Error!Try later',


                                            })
                                        })
                                    //}
                                    // else {
                                    //     this.setState({
                                    //         btntext:'Sending....'
                                    //     })
                                    //     const formbody =
                                    //     {
                                    //         "contact_form": [{ "target_id": "enquiry_form" }],
                                    //         "field_name": [{ "value": this.state.upname }],
                                    //         "field_phone": [{ "value":"+"+this.state.cc_code+"-"+ this.state.upphone }],
                                    //         "field_location": [{ "value": this.state.uplocation }],
                                    //         "message": [{ "value": this.state.upmsg }],
                                    //         "field_email": [{ "value": this.state.upmail }],
                                    //         "subject": [{ "value": "Form" }]

                                    //     }

                                    //     console.log(formbody)
                                    //     Axios(BASEURL + '/entity/contact_message?_format=json', {

                                    //         method: "POST",
                                    //         headers: {
                                    //             'Content-Type': 'application/json'
                                    //         },
                                    //         data: formbody
                                    //     })
                                    //         .then((res) => {

                                    //             document.getElementsByTagName("BODY")[0].style.overflow = "auto";

                                    //             this.setState({
                                    //                 upmsg: '',
                                    //                 uplocation: '',
                                    //                 upmail: '',
                                    //                 upname: '',
                                    //                 upphone: '',
                                    //                 formvalid: true,
                                    //                 formsuccess: true,
                                    //                 formvalidtext: 'Thank you!Your Submission has been received',
                                    //                 formsubmit: false,
                                    //                 form2: true,
                                    //                 popup: false,
                                    //                 btntext:'Send',
                                    //                 pophum:false

                                    //             })
                                    //             this.clearforms()
                                    //             setTimeout(
                                    //                 function () {
                                    //                     this.setState({ formvalid: false, form2: false });
                                    //                 }
                                    //                     .bind(this),
                                    //                 3000
                                    //             );
                                    //         })
                                    //         .catch((err) => {
                                    //             console.log(err,'formerror')

                                    //             this.setState({
                                    //                 formvalid: true,
                                    //                 btntext:'Send',
                                    //                 formvalidtext: 'Server Error!Try later',
                                    //             })

                                    //         })
                                    // }
                                }
                                else {
                                    this.setState({
                                        formvalid: true,
                                        form2: true,
                                        formvalidtext: 'Please verify you are a human',
                                        formsuccess: false

                                    })
                                    setTimeout(
                                        function () {
                                            this.setState({ formvalid: false, form2: false });
                                        }
                                            .bind(this),
                                        3000
                                    );
                                }
                            }
                            else {
                                this.setState({
                                    formvalid: true,
                                    form2: true,
                                    formvalidtext: 'Please enter valid details',
                                    formsuccess: false

                                })
                                setTimeout(
                                    function () {
                                        this.setState({ formvalid: false, form2: false });
                                    }
                                        .bind(this),
                                    3000
                                );
                            }
                        }
                        else {
                            this.setState({
                                formvalid: true,
                                form2: true,
                                formvalidtext: 'Please enter valid email address',
                                formsuccess: false

                            })
                            setTimeout(
                                function () {
                                    this.setState({ formvalid: false, form2: false });
                                }
                                    .bind(this),
                                3000
                            );
                        }
                    }
                    else {
                        this.setState({
                            formvalid: true,
                            form2: true,
                            formvalidtext: 'Please enter valid details',
                            formsuccess: false

                        })
                        setTimeout(
                            function () {
                                this.setState({ formvalid: false, form2: false });
                            }
                                .bind(this),
                            3000
                        );
                    }

                }
                else {
                    this.setState({
                        formvalid: true,
                        form2: true,
                        formvalidtext: 'Please enter valid details',
                        formsuccess: false

                    })
                    setTimeout(
                        function () {
                            this.setState({ formvalid: false, form2: false });
                        }
                            .bind(this),
                        3000
                    );
                }
            }
            else {
                this.setState({
                    formvalid: true,
                    form2: true,
                    formvalidtext: 'Please enter a valid phone number',
                    formsuccess: false

                })
                setTimeout(
                    function () {
                        this.setState({ formvalid: false, form2: false });
                    }
                        .bind(this),
                    3000
                );
            }
        }

        else {
            this.setState({
                formvalid: true,
                form2: true,
                formvalidtext: 'Please enter valid details',
                formsuccess: false

            })
            setTimeout(
                function () {
                    this.setState({ formvalid: false, form2: false });
                }
                    .bind(this),
                3000
            );
        }

    }
    uploadnew() {

        this.setState({ formsubmit: true, form2: true });
        setTimeout(
            function () {
                this.setState({ formsubmit: false, form2: false });
            }
                .bind(this),
            10000
        );
        if (this.state.upname) {

            if (this.state.upphone.length === 10) {
                // if (this.state.uplocation) {

                if (this.state.upmail) {
                    if (this.validateEmail(this.state.upmail)) {
                        if (this.state.upmsg) {
                            if (this.state.funding_type && this.state.bussiness_city && this.state.bussiness_type) {
                                if (this.state.pophum) {
                                    // if (filepath !== null) {

                                    this.setState({
                                        btntext: 'Sending....'
                                    })
                                    const formbody = this.state.uplocation ?
                                        {
                                            "contact_form": [{ "target_id": "enquiry_form_get_funded" }],
                                            "field_phone": [{ "value": "+" + this.state.cc_code + "-" + this.state.upphone }],
                                            "field_location": [{ "value": this.state.uplocation }],
                                            "message": [{ "value": this.state.upmsg }],
                                            "field_email": [{ "value": this.state.upmail }],
                                            "subject": [{ "value": "Form" }],
                                            "field_needed_funding_type": [{ "value": this.state.funding_type }],
                                            "field_business_city": [{ "value": this.state.bussiness_city }],
                                            "field_business_type_category": [{ "value": this.state.bussiness_type }],
                                            "field_tell_us": [{ "value": this.state.upmsg }],
                                            // "field_file_link": [{ "value": filepath ? filepath : null }],
                                            "field_name": [{ "value": this.state.upname }],
                                        } :
                                        {
                                            "contact_form": "enquiry_form_get_funded",
                                            "field_phone": [{ "value": "+" + this.state.cc_code + "-" + this.state.upphone }],
                                            // "field_location": this.state.uplocation,
                                            "message": [{ "value": this.state.upmsg }],
                                            "field_email": [{ "value": this.state.upmail }],
                                            "subject": [{ "value": "Form" }],
                                            "field_needed_funding_type": [{ "value": this.state.funding_type }],
                                            "field_business_city": [{ "value": this.state.bussiness_city }],
                                            "field_business_type_category": [{ "value": this.state.bussiness_type }],
                                            "field_tell_us": [{ "value": this.state.upmsg }],
                                            // "field_file_link": [{ "value": filepath ? filepath : null }],
                                            "field_name": [{ "value": this.state.upname }],
                                        }
                                    console.log(formbody)
                                    Axios(BASEURL + '/entity/contact_message?_format=json', {

                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        data: formbody
                                    })
                                        .then((res) => {
                                            this.setState({
                                                uploadtxt: 'File Upload'
                                            })


                                            document.getElementsByTagName("BODY")[0].style.overflow = "auto";


                                            filepath = null;


                                            this.setState({
                                                upmsg: '',
                                                upmail: '',
                                                uplocation: '',
                                                upname: '',
                                                upphone: '',
                                                uploadtxt: 'File Upload',
                                                bussiness_type: "",
                                                bussiness_city: "",
                                                funding_type: "",
                                                formvalid: true,
                                                formsuccess: true,
                                                formvalidtext: 'Thank you!Your Submission has been received',
                                                formsubmit: false,
                                                form2: true,
                                                popup: false,
                                                btntext: 'Send',
                                                pophum: false
                                            })
                                            this.clearforms()

                                            setTimeout(
                                                function () {
                                                    this.setState({ formvalid: false, form2: false });
                                                }
                                                    .bind(this),
                                                3000
                                            );
                                        })
                                        .catch((err) => {
                                            console.log(err, 'formerror')
                                            this.setState({
                                                formvalid: true,
                                                btntext: 'Send',
                                                formvalidtext: 'Server Error!Try later',


                                            })
                                        })
                                }
                                else {
                                    this.setState({
                                        formvalid: true,
                                        form2: true,
                                        formvalidtext: 'Please verify you are a human',
                                        formsuccess: false

                                    })
                                    setTimeout(
                                        function () {
                                            this.setState({ formvalid: false, form2: false });
                                        }
                                            .bind(this),
                                        3000
                                    );
                                }
                            }
                            else {
                                this.setState({
                                    formvalid: true,
                                    form2: true,
                                    formvalidtext: 'Please enter valid details',
                                    formsuccess: false

                                })
                                setTimeout(
                                    function () {
                                        this.setState({ formvalid: false, form2: false });
                                    }
                                        .bind(this),
                                    3000
                                );
                            }
                        }
                        else {
                            this.setState({
                                formvalid: true,
                                form2: true,
                                formvalidtext: 'Please enter valid details',
                                formsuccess: false

                            })
                            setTimeout(
                                function () {
                                    this.setState({ formvalid: false, form2: false });
                                }
                                    .bind(this),
                                3000
                            );
                        }
                    }
                    else {
                        this.setState({
                            formvalid: true,
                            form2: true,
                            formvalidtext: 'Please enter valid email address',
                            formsuccess: false

                        })
                        setTimeout(
                            function () {
                                this.setState({ formvalid: false, form2: false });
                            }
                                .bind(this),
                            3000
                        );
                    }
                }
                else {
                    this.setState({
                        formvalid: true,
                        form2: true,
                        formvalidtext: 'Please enter valid details',
                        formsuccess: false

                    })
                    setTimeout(
                        function () {
                            this.setState({ formvalid: false, form2: false });
                        }
                            .bind(this),
                        3000
                    );
                }
            }
            else {
                this.setState({
                    formvalid: true,
                    form2: true,
                    formvalidtext: 'Please enter a valid phone number',
                    formsuccess: false

                })
                setTimeout(
                    function () {
                        this.setState({ formvalid: false, form2: false });
                    }
                        .bind(this),
                    3000
                );
            }
        }

        else {
            this.setState({
                formvalid: true,
                form2: true,
                formvalidtext: 'Please enter valid details',
                formsuccess: false

            })
            setTimeout(
                function () {
                    this.setState({ formvalid: false, form2: false });
                }
                    .bind(this),
                3000
            );
        }
    }
    validateEmail(email) {
        //eslint-disable-next-line
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(email) === false) {

            return false;
        }

        return true;

    }
    testimonial() {

        cachios.get(BASEURL + '/jsonapi/node/testimonial?include=field_dp', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let i, j;
                this.setState({
                    testimg: true
                })
                this.setState({
                    testimonial: data.data.data.reverse(),
                    testlen: data.data.data.length,
                    testimg: data.data.included.reverse()
                })



                for (i = 0; i < data.data.data.length; i++) {

                    if (data.data.data[i].relationships.field_dp.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_dp.data.id === data.data.included[j].id) {
                                imagearray2.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        imagearray2.push(dummy)
                    }

                }
                this.setState({
                    imagearray2: imagearray2
                })
                if (imagearray2.length === data.data.data.length) {
                    this.setState({
                        testimg: true
                    })
                }






            })

            .catch((err) => {
                console.log('not found')
            })

        //testimonLogo

        cachios.get(BASEURL + '/jsonapi/node/testimonial?include=field_company_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                console.log(data, 'zzz')
                let i, j;
                this.setState({
                    testlogo: true
                })


                for (i = 0; i < data.data.data.length; i++) {

                    if (data.data.data[i].relationships.field_company_logo.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_company_logo.data.id === data.data.included[j].id) {
                                logoarray2.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        logoarray2.push(white)
                    }

                }

                this.setState({
                    logoarray2: logoarray2.reverse()
                })
                if (logoarray2.length === data.data.data.length) {
                    this.setState({
                        testlogo: true
                    })
                }



            })
            .catch((err) => {
                console.log('not found')
            })


    }
    clearforms() {
        var $el = $('#sortpicture');
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
        var $el1 = $('#sortpicture1');
        $el1.wrap('<form>').closest('form').get(0).reset();
        $el1.unwrap();
    }
    componentDidMount() {
        var textAreas = document.getElementsByTagName('textarea');

        Array.prototype.forEach.call(textAreas, function (elem) {
            elem.placeholder = elem.placeholder.replace(/\\n/g, '\n');
        });
    }

    componentWillMount() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";

        this.topFunction();
        //titleimg

        cachios.get(BASEURL + '/jsonapi/block_content/description_image?include=field_image', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    title: data.data
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //whatwelook

        cachios.get(BASEURL + '/jsonapi/block_content/title_description', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let i = 0;
                for (i = 0; i < data.data.data.length; i++) {
                    if (data.data.data[i].attributes.info === "Promoters and Teams" || data.data.data[i].attributes.info === "Zealous entrepreneurs") {
                        this.setState({
                            whatwe1: data.data.data[i]
                        })
                    }
                    else if (data.data.data[i].attributes.info === "Businesses") {
                        this.setState({
                            whatwe2: data.data.data[i]
                        })
                    }
                    else if (data.data.data[i].attributes.info === "How Does Rehbar Work For Businesses?") {
                        this.setState({
                            whatwe3: data.data.data[i]
                        })
                    }

                }

            })
            .catch((err) => {
                console.log('not found')
            })


        cachios.get(BASEURL + '/jsonapi/node/career', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {

                this.setState({
                    career: data.data.data
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //testimonial
        this.testimonial()
        //sliderCompleted


        cachios.get(BASEURL + '/jsonapi/node/deals?filter[field_status]=completed&include=field_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    slidercomp: data.data.included
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //sliderProposed
        cachios.get(BASEURL + '/jsonapi/node/deals?filter[field_status]=proposed&include=field_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    sliderprop: data.data.included
                })
            })
            .catch((err) => {
                console.log('not found')
            })



    }

    callback(response) {
        if (response) {
            console.log('user verified')

            this.setState({
                pophum: true
            })
        }
    }

    loaded() {
        console.log('loaded')
    }

    render() {
        const settings = {
            appendDots: (dots) => {
                if (this.state.width < 768)
                    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
                else
                    return <MagicSliderDots dots={dots} numDotsToShow={this.state.slidercomp.length} dotWidth={30} />
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.slidercomp ? this.state.slidercomp.length <= 3 ? this.state.slidercomp.length : this.state.width > 768 ? 4 : 2 : 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500
        };
        const recsettings = {
            appendDots: (dots) => {
                if (this.state.width < 768)
                    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
                else
                    return <MagicSliderDots dots={dots} numDotsToShow={this.state.sliderprop.length} dotWidth={30} />
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.sliderprop ? this.state.sliderprop.length <= 3 ? this.state.sliderprop.length : this.state.width > 768 ? 4 : 2 : 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500
        };
        return (

            <div className='getfundpage'>
                <div style={{ display: this.state.formvalid && this.state.form2 ? '' : 'none', backgroundColor: this.state.formsuccess ? '#28a745' : '', position: 'fixed', top: 0, width: '100%' }} className='formvalidation'>
                    {this.state.formvalidtext}
                </div>
                <div style={{ display: this.state.popup ? 'flex' : 'none' }} className='overlay'></div>
                <div style={{ display: this.state.popup ? 'flex' : 'none' }} className='popup popup_business'>
                    <div className='card_contact'>
                        <div onClick={() => this.close()} className='popup_close popup_close_bus cursor'>X</div>
                        <div className='card-title deal_title'>Submit your application below and We will get back to you.</div>

                        <Form id="myForm" className='fund_form_md'>
                            <div className='fund_form_md_sub'>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.upname && this.state.popup ? '1px solid red' : '' }} type="name" name="upname" id="examplePassword" placeholder="Name *" value={this.state.upname}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>

                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && (!this.state.upmail || !this.validateEmail(this.state.upmail)) && this.state.popup ? '1px solid red' : '' }} type="email" name="upmail" id="exampleEmail" placeholder="Mail *" value={this.state.upmail}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>

                                <FormGroup>
                                    <div className="cc_code">+91</div>
                                    <Input style={{ border: this.state.formsubmit && this.state.upphone.length !== 10 && this.state.popup ? '1px solid red' : '', textIndent: 35 }} type="number" name="upphone" id="exampleNumber" placeholder="Phone Number *" value={this.state.upphone}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <select style={{ border: this.state.formsubmit && !this.state.funding_type && this.state.popup ? '1px solid red' : '' }} name='funding_type' value={this.state.funding_type} className='form-control' onChange={e => this.handleChange(e)}>
                                        <option value="">Select Funding Type*</option>
                                        <option value="Profit Sharing Loans">Profit Sharing Loans</option>
                                        <option value="Operating Lease">Operating Lease</option>
                                        <option value="Project Financing">Project Financing</option>
                                        <option value="Other Funding Options">Other Funding Options</option>
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.bussiness_city && this.state.popup ? '1px solid red' : '' }} type="text" name="bussiness_city" id="exampleNumber" placeholder="Bussiness City *" value={this.state.bussiness_city}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                {/* <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.bussiess_country && this.state.popup ? '1px solid red' : '' }} type="text" name="bussiess_country" id="exampleNumber" placeholder="Bussiness Country *" value={this.state.bussiess_country}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup> */}
                            </div>
                            <div className='fund_form_md_sub'>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.bussiness_type && this.state.popup ? '1px solid red' : '' }} type="text" name="bussiness_type" id="exampleNumber" placeholder="Bussiness Type/Category *" value={this.state.bussiness_type}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.upmsg && this.state.popup ? '1px solid red' : '' }} type="textarea" name="upmsg" id="exampleText" placeholder="Business Profile * \nFund requirements(amount) * \nApplication of funds (end-use) *" value={this.state.upmsg}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                            </div>



                        </Form>
                        <div className='buttons'>
                            <span className={filepath ? "btn d-none  btn-file" : "btn d-none btn-file"} id='upspan1' style={{ color: this.state.uploadtxt === "File Uploaded" ? '#ea410e' : '' }}>
                                <img src={attach} alt='attach' height='20px' />{this.state.uploadtxt} <input onChange={(e) => this.fileupload(e, '#sortpicture1', "upspan1")} id="sortpicture1" disabled={this.state.disable} type="file" />
                            </span>
                            <Button style={{ display: this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.upmail && this.state.upname && this.state.funding_type && this.state.bussiess_country && this.state.bussiness_city && this.state.bussiness_type ? 'none' : '' }} onClick={() => this.uploadnew()} className="col-md-3 btnshadow btnsend">{this.state.btntext}</Button>
                        </div>
                        <div className='note d-none'>
                            Note:Please zip and upload if there is more than one file to upload.
                        </div>
                        <div style={{ flexDirection: this.state.width < 768 ? '' : this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.upmail && this.state.upname && this.state.funding_type && this.state.bussiness_city && this.state.bussiness_type ? '' : 'row-reverse' }} className='buttons capsend'>
                            {this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.upmail && this.state.upname && this.state.funding_type && this.state.bussiness_city && this.state.bussiness_type ?

                                <div className='fund_form_md_sub'>
                                    <div className='note'>
                                        <Recaptcha
                                            sitekey="6LcaxaoUAAAAAIInWPI6qixEXDl_I6nbKtSdwHms"
                                            render="explicit"
                                            onloadCallback={this.loaded}
                                            verifyCallback={this.callback}
                                        />
                                    </div>
                                </div> : ''}
                            <Button style={{ display: this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.upmail && this.state.upname && this.state.funding_type && this.state.bussiess_country && this.state.bussiness_city && this.state.bussiness_type ? '' : 'none' }} onClick={() => this.uploadnew()} className="col-md-3 btnshadow btnsend">{this.state.btntext}</Button>
                        </div>

                    </div>
                    <div className='popup_footer'></div>


                </div>
                <div>
                    <Header />
                </div>
                {this.state.title ? <div className='fund_title'>
                    <div className='fund_title_card'>
                        <div className='getfundtopimg'><img src={this.state.title ? BASE + this.state.title.included[1].attributes.uri.url : ''} alt='funded' /></div>
                        <div className='fund_title_text'>

                            {ReactHtmlParser(this.state.title ? this.state.title.data[1].attributes.field_description[0].value : '')}

                        </div>
                    </div>
                    <div className='what_we'>

                        <div className='what_we_head'>How does a business qualify for funds</div>
                        <div className='what_we_div'>
                            <div className='what_we_headsub'>
                                <div><img src={gf1} alt='rehbar' /></div>
                                <div className='what_we_subhead'>{this.state.whatwe1 ? this.state.whatwe1.attributes.field_title.value : ''}</div>
                                <div>{ReactHtmlParser(this.state.whatwe1 ? this.state.whatwe1.attributes.field_description[0].processed : '')}
                                </div>
                            </div>
                            <div className='what_we_headsub'>
                                <div><img src={gf2} alt='rehbar' /></div>
                                <div className='what_we_subhead'>{this.state.whatwe2 ? this.state.whatwe2.attributes.field_title.value : ''}</div>
                                <div>{ReactHtmlParser(this.state.whatwe2 ? this.state.whatwe2.attributes.field_description[0].processed : '')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className='siteloading'><img src={loader} alt='hometitle' /> </div>}



                <div className='fund_div'>
                    <div className='fund_form_head'>Send us your queries and a Rehbar executive will get back to you immediately</div>
                    <div className='fund_form'>
                        <Form id="myForm" className='fund_form_md'>
                            <div className='fund_form_md_sub'>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.upname && !this.state.popup ? '1px solid red' : '' }} type="name" name="upname" id="examplePassword" placeholder="Name *" value={!this.state.popup ? this.state.upname : ''}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>

                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && (!this.state.upmail || !this.validateEmail(this.state.upmail)) && !this.state.popup ? '1px solid red' : '' }} type="email" name="upmail" id="exampleEmail" placeholder="Mail *" value={!this.state.popup ? this.state.upmail : ''}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>

                                <FormGroup>
                                    <div className="cc_code">+91</div>
                                    <Input style={{ border: this.state.formsubmit && this.state.upphone.length !== 10 && !this.state.popup ? '1px solid red' : '', textIndent: 35 }} type="number" name="upphone" id="exampleNumber" placeholder="Phone Number *" value={!this.state.popup ? this.state.upphone : ''}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.uplocation && !this.state.popup ? '1px solid red' : '' }} type="text" name="uplocation" id="exampleNumber" placeholder="Location *" value={!this.state.popup ? this.state.uplocation : ''}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                            </div>
                            <div className='fund_form_md_sub'>
                                <FormGroup>
                                    <Input style={{ border: this.state.formsubmit && !this.state.upmsg && !this.state.popup ? '1px solid red' : '' }} type="textarea" name="upmsg" id="exampleText" placeholder="Business Profile * \nFund requirements(amount) * \nApplication of funds (end-use) *" value={!this.state.popup ? this.state.upmsg : ''}
                                        onChange={e => this.handleChange(e)} />
                                </FormGroup>
                                {/* <FormGroup>
                                    
                                </FormGroup> */}

                            </div>



                        </Form>
                        <div className='buttons'>
                            <span className={filepath ? "btn  btn-file uploaded_span" : "btn  btn-file upload_span"} id='upspan' style={{ color: !this.state.popup && this.state.uploadtxt === "File Uploaded" ? '#ea410e' : '' }}>
                                <img src={attach} alt='attach' height='20px' />{this.state.popup ? 'File Upload' : this.state.uploadtxt} <input onChange={(e) => this.fileupload(e, '#sortpicture', "upspan")} id="sortpicture" disabled={this.state.disable} type="file" />
                            </span>
                            <Button style={{ display: !this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.uplocation && this.state.upmail && this.state.upname ? 'none' : '' }} onClick={() => this.upload()} className="col-md-3 btnsend">{this.state.popup ? 'Send' : this.state.btntext}</Button>
                        </div>
                        <div className='note'>
                            Note:Please zip and upload if there is more than one file to upload.
                        </div>
                        <div style={{ display: !this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.uplocation && this.state.upmail && this.state.upname ? '' : 'none', flexDirection: this.state.width < 768 ? '' : !this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.uplocation && this.state.upmail && this.state.upname ? '' : 'row-reverse' }} className='buttons capsend'>
                            {!this.state.popup && this.state.upphone.length === 10 && this.state.upmsg && this.state.uplocation && this.state.upmail && this.state.upname ?

                                <div className='fund_form_md_sub'>
                                    <div className='note'>
                                        <Recaptcha
                                            sitekey="6LcaxaoUAAAAAIInWPI6qixEXDl_I6nbKtSdwHms"
                                            render="explicit"
                                            onloadCallback={this.loaded}
                                            verifyCallback={this.callback}
                                        />
                                    </div>
                                </div>
                                : ''}
                            <Button onClick={() => this.upload()} className="col-md-3 btnsend">{this.state.popup ? 'Send' : this.state.btntext}</Button>
                        </div>


                        <div>

                        </div>

                    </div>
                </div>

                <div className='what_we fund_grey'>
                    <div className='card_container'>
                        <div className="card card-body">
                            <div className="card-title">Sign-Up as a Business</div>
                            <button onClick={() => this.popup()} className="btn">Sign Up</button>
                        </div>
                    </div>
                    <div className='what_we_head'>{this.state.whatwe3 ? this.state.whatwe3.attributes.field_title.value : ''}</div>
                    <div>{ReactHtmlParser(this.state.whatwe3 ? this.state.whatwe3.attributes.field_description[0].value : '')}
                    </div>
                </div>

                <div className='fund_deals_executed'>
                    <div className='deals_executed'>
                        <div className='card-title deal_title'>Prominent Businesses  Funded through Rehbar</div>

                        {this.state.slidercomp ? <Slider {...settings} >
                            {this.state.slidercomp ? this.state.slidercomp.map((slider, index) => {
                                return (
                                    <div key={index} className='deals_carousel'>
                                        <img src={BASE + slider.attributes.uri.url} alt='img' />
                                    </div>)
                            }) : ''}


                        </Slider> : ''}

                    </div>



                </div>

                <div className='fund_testimonials'>


                    <div className='card_container'>
                        <div className="card card-body">
                            <div className="card-title">Need funding for equipments / machineries / cars on Rental basis</div>
                            <button onClick={() => this.popup()} className="btn">Sign Up</button>
                        </div>
                    </div>
                    <div className='card-title deal_title'>Funded Businesses Testimonials</div>

                    <div className='testimonials'>
                        {this.state.testimonial && this.state.testimg && this.state.testlogo ?
                            <div className="testimonial_div">
                                <div className='testimonial_header'>
                                    <div className='testimonial_header_div1'>
                                        <div>
                                            <img className='img-circle' src={this.state.imagearray2 ? this.state.imagearray2[this.state.testindex] : dummy} height='120px' width='120px' alt='hometitle' />
                                        </div>
                                        <div className='profile'>
                                            <div className='card-title'>{this.state.testimonial[this.state.testindex].attributes.title}</div>
                                            <div className="card-text"> {this.state.testimonial[this.state.testindex].attributes.field_designation.value}</div>
                                        </div>
                                    </div>
                                    <div className='quote'>
                                        <img src={quote} height='70px' alt='hometitle' />
                                    </div>
                                </div>
                                <div className="testimonial_desc">
                                    {ReactHtmlParser(this.state.testimonial[this.state.testindex].attributes.field_description.value)}
                                </div>
                                <div className='testimonial_footer'>
                                    <div>
                                        {this.state.logoarray2 ? <img className='img-circle' src={this.state.logoarray2 ? this.state.logoarray2[this.state.testindex] : white} height='30px' alt='hometitle' /> : ''}
                                    </div>
                                    <div className='testimonial_arrow'>
                                        <div className="cursor"><img onClick={() => this.testindex(1)} src={this.state.testindex === 0 ? deactiveleft : activeleft} alt='hometitle' /></div>
                                        <div className="cursor"><img onClick={() => this.testindex(2)} src={this.state.testindex === this.state.testlen - 1 ? deactiveright : activeright} alt='hometitle' /></div>
                                    </div>
                                </div>
                            </div> : <div></div>}

                    </div>

                    <div className='card_container fund_testimonials_card_bottom'>
                        <div className="card card-body">
                            <div className="card-title">Need business funding on Profit and loss basis</div>
                            <button onClick={() => this.popup()} className="btn">Sign Up</button>
                        </div>
                    </div>



                </div>

                <div className='invest_bottomcard fund_newsletter'>
                    <Mail />
                    {/* <div className="card card-body">
                        <div className="card-title">Stay Updated</div>
                        <p className="card-text">We will keep you in the loop with ecosystem trends and insights
                            </p>
                        <Mailchimp
                            action='https://rehbar.us9.list-manage.com/subscribe/post?u=9dc1343d0af6884635113513e&amp;id=bea577df25'
                            fields={[
                                {
                                    name: 'EMAIL',
                                    placeholder: 'Your email id',
                                    type: 'email',
                                    required: true,
                                    button: "Sign Up for newsletters"
                                }
                            ]}
                            message={
                                {
                                    sending: "Sending...",
                                    success: "Thank you for subscribing!",
                                    error: "An unexpected internal error has occurred.",
                                    empty: "You must write an e-mail.",
                                    duplicate: "Too many subscribe attempts for this email address",
                                    button: "Sign Up for newsletters"
                                }
                            }
                        />

                    </div> */}
                </div>

                <div>
                    <Footer />
                </div>
            </div>
        )


    }

}

export default Funded;