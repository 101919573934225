import React, { Component } from "react";
import { BASE } from "../api/api";
import { AlertConsumer } from '../context/AlertContext';
class OtherDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pdf: this.props.pdf,
        }
    }
    shareTo(source, showAlert) {
        const url = window.location.href
        const title = this.props.title
        const link = document.createElement('a');
        if (source === "facebook") {
            link.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "linkedin") {
            link.href = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "x") {
            link.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "copy") {
            console.log("link copied")
            navigator.clipboard.writeText(window.location.href);
            showAlert('Link copied to Clipboard', 'success');
        }
    }
    render() {
        return (
            <div className="other-details mx-auto d-flex">
                {this.state.pdf ? <div className="detail-box"><a href={BASE + this.state.pdf} target="_blank"><img src="/assets/pdf.png" /> <span>Document/Attachment</span></a></div> : ""}
                <AlertConsumer>
                    {({ showAlert }) => (
                        <div className="detail-box" onClick={() => { this.shareTo("copy", showAlert) }}><img src="/assets/copy.png" /> <span>Copy link</span></div>
                    )}
                </AlertConsumer>
                <div className="detail-box" onClick={() => this.shareTo("x")}><img src="/assets/x.png" /></div>
                <div className="detail-box" onClick={() => this.shareTo("facebook")}><img src="/assets/facebook.png" /></div>
                <div className="detail-box" onClick={() => this.shareTo("linkedin")}><img src="/assets/linkedin.png" /></div>
            </div>
        )
    }
}
export default OtherDetails;