import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import cachios from 'cachios'
import { BASEURL } from "../api/api";
import './faq.css'
import Header from '../header/header'
import Footer from '../footer/footer'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Recaptcha from 'react-recaptcha'

class Faq extends Component {
    constructor(props) {
        super(props);
        this.callback=this.callback.bind(this)
        this.loaded=this.loaded.bind(this)
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '2',
          business:''
        };
      }
     

    componentWillMount(){
    document.getElementsByTagName("BODY")[0].style.overflow = "auto";

    //invest tab
  
    cachios.get(BASEURL+'/jsonapi/node/faq?filter[field_type]=investor', {
        ttl: 300 /* seconds */,
        })  
        .then((data) => {
            console.log(data,'prog')

            this.setState({
                invest:data.data.data
            })
        })
        .catch((err) => {
            console.log('not found')
        })
    //businness
            
    cachios.get(BASEURL+'/jsonapi/node/faq?filter[field_type]=business', {
        ttl: 300 /* seconds */,
        })   
        .then((data) => {
            this.setState({
                business:data.data.data
            })
        })
        .catch((err) => {
            console.log('not found')
        })

    }

    callback(response){
        if(response)
        console.log('user verified')
    }

    loaded(){
        console.log('loaded')
    }
    
      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }
      render() {
       
        return (
            <div className='faq'>
                <div>
                    <Header />
                </div>

                {/* <div>
                    Captcha
                    <Recaptcha
                        sitekey="6LcaxaoUAAAAAIInWPI6qixEXDl_I6nbKtSdwHms"
                        render="explicit"
                        onloadCallback={this.loaded}
                        verifyCallback={this.callback}
                        />
                </div> */}




                {/* <div className='contact_title card-title'>
                    <div className='faq_header'>
                        <div>FAQ</div>
               
                    </div>    
                </div> */}

                <div className='contact_title card-title'>
                    <div className='faq_header'>
                        <div>FAQ</div>
                        {this.state.invest?this.state.invest.length!==0?<div className='faq_button2'>
                            <div onClick={() => { this.toggle('1'); }} className={this.state.activeTab==='1'?'faq_button2_active':'faq_button2_inactive'}>Investor</div>  
                            <div onClick={() => { this.toggle('2'); }} className={this.state.activeTab==='2'?'faq_button2_active':'faq_button2_inactive'}>Business</div>
                        </div>:'':''}
                    </div>    
                </div>
                

                <div className='contact_form' style={{display:this.state.activeTab==='2'?'':'none'}}>
                    <Accordion>
                        {this.state.business?this.state.business.map((invest, index) => {
                            return(
                                <AccordionItem   key={index}  style={{borderBottom:index===this.state.business.length-1?'none':''}} expanded={index===0?true:false}>
                                    <AccordionItemTitle className='accordion__title'>
                                        <div className='sub'>
                                            <div className='card-title'>{invest.attributes.title}</div>
                                            <div className="accordion__arrow" role="presentation"></div>
                                        </div>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {ReactHtmlParser(invest.attributes.field_answer_description_.value)}                                
                                    </AccordionItemBody>
                                </AccordionItem>
                                )})
                        :''}
                                
                    </Accordion>
                </div>
                <div className='contact_form' style={{display:this.state.activeTab==='1'?'':'none'}}>
                    <Accordion>
                    {this.state.invest?this.state.invest.length!==0?this.state.invest.map((invest, index) => {
                        return(
                            <AccordionItem style={{borderBottom:index===this.state.invest.length-1?'none':''}} expanded={index===0?true:false}>
                                <AccordionItemTitle className='accordion__title'>
                                    <div className='sub'>
                                        <div className='card-title'>{invest.attributes.title}</div>
                                        <div class="accordion__arrow" role="presentation"></div>
                                    </div>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    {ReactHtmlParser(invest.attributes.field_answer_description_.value)}                                
                                </AccordionItemBody>
                            </AccordionItem>
                            )})
                     :'':''}
                    </Accordion>
                </div>
                
                

                <div>
        
                 </div>

                <div >
                    <Footer />
                </div>


            </div>
)}

}

export default Faq;
