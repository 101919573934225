import React, { createContext, Component } from 'react';

const AlertContext = createContext();

export class AlertProvider extends Component {
    state = {
        alert: null,
    };

    showAlert = (message, type = 'info') => {
        this.setState({ alert: { message, type } });
        setTimeout(() => this.setState({ alert: null }), 3000); // Hide alert after 3 seconds
    };

    render() {
        return (
            <AlertContext.Provider
                value={{
                    alert: this.state.alert,
                    showAlert: this.showAlert,
                }}
            >
                {this.props.children}
            </AlertContext.Provider>
        );
    }
}

export const AlertConsumer = AlertContext.Consumer;
