import React, { Component } from "react";
import loader from '../assets/loading.gif'


class Loader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: this.props.type ? this.props.type : "normal"
        }
    }
    render() {
        return <>
            {this.props.type === "carousel" ? <div className="siteloading">
                <div className="row w-100">
                    {Array(6).fill().map(x => {
                        return <div className={`${this.props.column === "2" ? "col-sm-6" : "col-sm-4"} col-12 mb-3`}>
                            <div className="loader-orange mb-2" style={{ height: "240px" }} />
                            <div className="loader-orange mb-2" style={{ height: "32px" }} />
                            <div className="loader-orange mb-1" style={{ height: "24px", width: "90%" }} />
                            <div className="loader-orange" style={{ height: "24px", width: "80%" }} />
                        </div>
                    })}
                </div>
            </div> : this.props.type === "content" ?
                <div className='w-100'>
                    <div className="loader-orange mb-2" style={{ height: "32px" }} />
                    {Array(3).fill().map((x, i) => {
                        return <div className="loader-orange mb-2" style={{ height: "24px", width: i == 0 ? "90%" : "80%" }} />
                    })}
                </div>
                : <div className='siteloading'><img src={loader} alt='hometitle' /> </div >}
        </>
    }
}
export default Loader;