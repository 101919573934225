import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import error from '../assets/403.png'
class error403 extends Component {
     
render() {
    return (
        <div className='errorimg'>
            <div>
                <div><img src={error} alt='error'/></div>
            </div>
            <div>
                <div className='error'>403</div>
                <div className='error_grey'>lreom ipsumjsdi kjsadie ajshdj ajshdah fsds</div>
                <Link to='/'><div className='gotohome'>Go to home</div></Link>
            </div>        
        </div>      
        
            )
    
    
}

}

export default error403;





