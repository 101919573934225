import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { AlertConsumer } from '../context/AlertContext';
import { Collapse, Navbar, Nav } from 'reactstrap';
import logo from '../assets/Rehbar_logo.png'
import './header.css'


class Header extends Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            cl_ser: false,
            cl_abt: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (<>
            <AlertConsumer>
                {({ alert }) => {
                    if (!alert) return null;
                    return (
                        <div style={{ backgroundColor: alert.type === "success" ? '#28a745' : '', position: 'fixed', top: 0, width: '100%' }} className={`formvalidation w-100 alert-${alert.type}`}>
                            {alert.message}
                        </div>
                    );
                }}
            </AlertConsumer>
            <div className='header'>
                <Navbar expand="md" color="faded" light>
                    <span className="rehbar_logo mr-auto">
                        <Link to='/'><img src={logo} alt='logo' /></Link></span>
                    <button style={{ border: 'none' }} onClick={this.toggleNavbar} type="button" className="navbar-toggler">
                        <span className="navbar-toggler-icon">
                        </span>
                    </button>
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav className='navbar_mobile' navbar>
                            <Link to='/'><div style={{ display: window.location.pathname === '/' ? 'none' : '' }}>Home</div></Link>
                            <div style={{ color: this.state.cl_abt === true ? '#EF3E23' : '' }} onClick={() => this.setState({ cl_abt: !this.state.cl_abt, abt: !this.state.abt })} className='about_header2' onMouseEnter={() => this.setState({ abt: true })} onMouseLeave={() => this.setState({ abt: false })}><div style={{ color: window.location.pathname === '/about-us' || window.location.pathname === '/about-us/who-we-are' || window.location.pathname === '/about-us/how-we-works' || window.location.pathname === '/about-us/our-team' || window.location.pathname === '/about-us/some-of-our-clients' ? '#EF3E23' : "" }}>About</div><div className='plus_min' onClick={() => this.setState({ cl_abt: !this.state.cl_abt })}>{this.state.cl_abt ? '-' : '+'}</div>
                                <div className="cl_menu" style={{ display: this.state.cl_abt === true ? '' : 'none' }}>
                                    <Link to='/about-us/who-we-are'><div>Who We Are</div></Link>
                                    <Link to='/about-us/how-we-works'> <div>How We Work</div></Link>
                                    <Link to='/about-us/our-team'><div>The Team</div></Link>
                                    <Link to='/about-us/some-of-our-clients'><div>Some of our Clients</div></Link>
                                    {/* <Link to='/about-us/foundation'><div>Rehbar Foundation</div></Link> */}

                                </div>
                                <div className="pr_menu" style={{ display: this.state.abt === true ? '' : 'none' }}>
                                    <Link to='/about-us/who-we-are'><div>Who We Are</div></Link>
                                    <Link to='/about-us/how-we-works'> <div>How We Work</div></Link>
                                    <Link to='/about-us/our-team'><div>The Team</div></Link>
                                    <Link to='/about-us/some-of-our-clients'><div>Some of our Clients</div></Link>
                                    {/* <Link to='/about-us/foundation'><div>Rehbar Foundation</div></Link> */}

                                </div>
                            </div>
                            <div onClick={() => this.setState({ cl_ser: !this.state.cl_ser, pr_ser: !this.state.pr_ser })} className='about_header2' onMouseEnter={() => this.setState({ pr_ser: true })} onMouseLeave={() => this.setState({ pr_ser: false })}><div style={{ color: window.location.pathname === '/products_and_services/products' || window.location.pathname === '/products_and_services/services' || window.location.pathname === '/products_and_services/faq' ? '#EF3E23' : "" }} >Products and Services</div><div className='plus_min' onClick={() => this.setState({ cl_ser: !this.state.cl_ser })}>{this.state.cl_ser ? '-' : '+'}</div>
                                <div className="cl_menu" style={{ display: this.state.cl_ser === true ? '' : 'none' }}>
                                    <Link to='/products_and_services/products'><div>Products</div></Link>
                                    <Link to='/products_and_services/services'> <div>Services</div></Link>
                                    <Link to='/products_and_services/faq'><div>FAQ</div></Link>
                                </div>
                                <div className="pr_menu" style={{ display: this.state.pr_ser === true ? '' : 'none' }}>
                                    <Link to='/products_and_services/products'><div>Products</div></Link>
                                    <Link to='/products_and_services/services'> <div>Services</div></Link>
                                    <Link to='/products_and_services/faq'><div>FAQ</div></Link>
                                </div>

                            </div>
                            <Link to='/invest'><div style={{ color: window.location.pathname === '/invest' ? '#EF3E23' : '' }}>Invest</div></Link>
                            <Link to='/get_funded'><div style={{ color: window.location.pathname === '/get_funded' ? '#EF3E23' : '' }}>Get Funded</div></Link>
                            {/* <div onClick={() => this.setState({ cl_press: !this.state.cl_press, pr_press: !this.state.pr_press })} className='about_header2' onMouseEnter={() => this.setState({ pr_press: true })} onMouseLeave={() => this.setState({ pr_press: false })}><div style={{ color: window.location.pathname === '/press-release' || window.location.pathname === '/testimonials' || window.location.pathname === '/awards' || window.location.pathname === '/assets' || window.location.pathname === '/events' || window.location.pathname === '/interviews' ? '#EF3E23' : '' }} >Press</div><div className='plus_min' onClick={() => this.setState({ cl_press: !this.state.cl_press })}>{this.state.cl_press ? '-' : '+'}</div>
                                <div className="cl_menu" style={{ display: this.state.cl_press === true ? '' : 'none' }}>
                                    <Link to='/press-release'><div>Press Release</div></Link>
                                    <Link to='/testimonials'><div>Testimonials</div></Link>
                                    <Link to='/awards'><div>Awards</div></Link>
                                    <Link to='/assets'><div>Assets</div></Link>
                                    <Link to='/events'><div>Events</div></Link>
                                    <Link to='/interviews'><div>Interviews</div></Link>
                                    <Link to='/investment-news'><div>Investment News</div></Link>
                                </div>
                                <div className="pr_menu" style={{ display: this.state.pr_press === true ? '' : 'none' }}>
                                    <Link to='/press-release'><div>Press Release</div></Link>
                                    <Link to='/testimonials'><div>Testimonials</div></Link>
                                    <Link to='/awards'><div>Awards</div></Link>
                                    <Link to='/assets'><div>Assets</div></Link>
                                    <Link to='/events'><div>Events</div></Link>
                                    <Link to='/interviews'><div>Interviews</div></Link>
                                    <Link to='/investment-news'><div>Investment News</div></Link>
                                </div>
                            </div> */}
                            {/* <Link to='/join_us'><div style={{color:window.location.pathname==='/join_us'?'#EF3E23':''}}>Join Us</div></Link> */}
                            <Link to='/join_us'><div style={{ color: window.location.pathname === '/join_us' ? '#EF3E23' : '' }}>Join Us</div></Link>
                            <Link to='/blog'><div style={{ color: window.location.pathname === '/blog' ? '#EF3E23' : '' }}>Blog</div></Link>
                            {/* <a className='nohover' rel="noopener noreferrer" target="_blank" href='https://blog.rehbar.co.in/'><div>Blog</div></a> */}
                            {/* <div onClick={() => this.setState({ cl_blog: !this.state.cl_blog, pr_blog: !this.state.pr_blog })} className='about_header2' onMouseEnter={() => this.setState({ pr_blog: true })} onMouseLeave={() => this.setState({ pr_blog: false })}><div style={{ color: window.location.pathname === '/blog' || window.location.pathname === '/podcast' ? "#EF3E23" : "" }} >Blog</div><div className='plus_min' onClick={() => this.setState({ cl_blog: !this.state.cl_blog })}>{this.state.cl_blog ? '-' : '+'}</div>
                                <div className="cl_menu" style={{ display: this.state.cl_blog === true ? '' : 'none' }}>
                                    <Link to='/blog'><div>Blog</div></Link>
                                    <Link to='/podcast'><div>Podcast</div></Link>
                                    <Link to='/blog?type=Knowledge Base'><div>Knowledge Base</div></Link>
                                </div>
                                <div className="pr_menu" style={{ display: this.state.pr_blog === true ? '' : 'none' }}>
                                    <Link to='/blog'><div>Blog</div></Link>
                                    <Link to='/podcast'><div>Podcast</div></Link>
                                    <Link to='/blog?type=Knowledge Base'><div>Knowledge Base</div></Link>
                                </div>
                            </div> */}
                            <Link to='/contact'><div style={{ color: window.location.pathname === '/contact' ? '#EF3E23' : '' }}>Contact</div></Link>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </>
        )
    }
}

export default Header;
