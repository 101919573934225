import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import error from '../assets/404.png'
import './404.css'
class error404 extends Component {
     
render() {
    return (
        <div className='errorimg'>
            <div>
                <div><img src={error} alt='error'/></div>
            </div>
            <div>
                <div className='error'>404</div>
                <div className='error_grey'>Page not found</div>
                <Link to='/'><div className='gotohome'>Go to home</div></Link>
            </div>        
        </div>   
        
            )
    
    
}

}

export default error404;





