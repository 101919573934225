import React, { Component } from 'react';
import cachios from 'cachios'
import { BASEURL, BASE } from "../api/api";
import Header from '../header/header'
import Footer from '../footer/footer'
import dummy from '../assets/dummyimg.jpg'
import './repo.css'
import ReactHtmlParser from 'react-html-parser';

class Repo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagearray: [],
            team: ''
        };
    }

    goto(link){
        window.open(link, "_blank");
    }

    componentWillMount() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
        cachios.get(BASEURL + '/jsonapi/taxonomy_term/repository_category?include=field_category_icon', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                console.log(data, 'prog')
                let i, j, imagearray = [];
                this.setState({
                    team: data.data.data,
                })

                for (i = 0; i < data.data.data.length; i++) {
                    if (data.data.data[i].relationships.field_category_icon.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_category_icon.data.id === data.data.included[j].id) {
                                imagearray.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        imagearray.push(dummy)
                    }
                }

                this.setState({
                    imagearray: imagearray
                })

                if (imagearray.length === data.data.data.length) {
                    this.setState({
                        testimg: true
                    })
                }

            })

            .catch((err) => {
                console.log('not found')
            })
    }

    render() {
        return (
            <div>
                <div>
                    <Header />
                </div>
                <div className="repodiv">
                    <div id="products" class="contact_title card-title">Content Repository</div>
                    <div className="repo">
                        {this.state.team && this.state.imagearray.length !== 0 ? this.state.team.map((team, index) => {
                            return (
                                <a target="_blank" rel="noopener noreferrer" href={team.attributes.field_link?team.attributes.field_link.uri:''}>
                                    <div key={index} className="repo-block">
                                            <div>
                                                <img alt="repo" src={this.state.imagearray[index]} />
                                            </div>
                                            <div className="card-title">
                                                {team.attributes.name}
                                            </div>
                                            <p className="repo-desc">
                                                {ReactHtmlParser(team.attributes.description ? team.attributes.description.value : '')}
                                            </p>
                                    </div>
                                </a>
                            )
                        }) : ''}
                    </div>
                </div>
                <div >
                    <Footer />
                </div>
            </div>
        )
    }

}

export default Repo;
