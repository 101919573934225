import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion'; import cachios from 'cachios'
import { BASEURL } from "../api/api";
import Header from '../header/header'
import Footer from '../footer/footer'
import './products.css'

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prod: '',
            scroll: props.match.params.id,
            activeTab: '2'

        }
    }
    componentWillMount() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";

        //invest tab

        cachios.get(BASEURL + '/jsonapi/node/faq?filter[field_type]=investor', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                console.log(data, 'prog')

                this.setState({
                    invest: data.data.data
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        cachios.get(BASEURL + '/jsonapi/node/faq?filter[field_type]=business', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    business: data.data.data
                })
                if (this.state.prod && this.state.serv && this.state.business) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })
        cachios.get(BASEURL + '/jsonapi/node/product', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let prod = []
                let serv = []
                //eslint-disable-next-line
                data.data.data.map((dat, index) => {
                    if (dat.attributes.field_type1 === "Products") {
                        prod.push(dat)
                    }
                    else {
                        serv.push(dat)
                    }
                })
                this.setState({
                    prod: prod,
                    serv: serv
                })
                if (this.state.prod && this.state.serv && this.state.business) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })


    }
    componentWillReceiveProps(nextProps) {

        this.scroll(nextProps.match.params.id)

    }
    scroll(pos) {
        const element = document.getElementById(pos);

        if (element) {
            let topPos = element.offsetTop;
            document.body.scrollTop = topPos; // For Safari
            document.documentElement.scrollTop = topPos;
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (

            <div className='product_page'>
                <div>
                    <Header />
                </div>
                <div id="products" className='contact_title card-title'>
                    Products
                </div>
                <div className='products_div' >
                    {this.state.prod ? this.state.prod.reverse().map((invest, index) => {
                        return (
                            <div key={index} className='products' style={{ borderBottom: index === this.state.prod.length - 1 ? 'none' : '' }}>
                                <div className='products_head'>{invest.attributes.title}</div>
                                <div className='row'>
                                    <div className={`features ${invest.attributes.field_benefits ? "col-md-6" : "col-md-12"}`}>
                                        <div className='features_head'>Features</div>
                                        {invest.attributes.field_features ? <div>{ReactHtmlParser(invest.attributes.field_features.value)}</div> : <div></div>}
                                    </div>
                                    {invest.attributes.field_benefits ? <div className='features col-md-6 jdsakdjsa'>
                                        <div className='features_head'>Benefits</div>
                                        <div className='benefit_list'>
                                            <div className='benefit_list_item'>
                                                {invest.attributes.field_benefits ? <div>{ReactHtmlParser(invest.attributes.field_benefits.value)}</div> : <div></div>}

                                            </div>
                                        </div>
                                    </div> : ""}
                                </div>
                            </div>)
                    }) : ''}

                </div>

                <div id="services" className='contact_title card-title'>
                    Services
                </div>
                <div className='products_div service_div' >
                    {this.state.serv ? this.state.serv.reverse().map((invest, index) => {
                        return (
                            <div key={index} className='products' style={{ borderBottom: index === this.state.prod.length ? 'none' : '' }}>
                                <div className='products_head'>{invest.attributes.title}</div>
                                <div className='row'>
                                    <div className='features col-md-12'>
                                        {invest.attributes.field_features ? <div>{ReactHtmlParser(invest.attributes.field_features.value)}</div> : <div></div>}
                                    </div>
                                    <div className='features col-md-6' style={{ display: 'none' }}>
                                        <div className='features_head'>Benefits</div>
                                        <div className='benefit_list'>
                                            <div className='benefit_list_item'>
                                                {invest.attributes.field_features ? <div>{ReactHtmlParser(invest.attributes.field_benefits.value)}</div> : <div></div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    }) : ''}

                </div>
                {/* <div className='contact_title card-title' id="faq">
                    FAQ
                
                
                </div> */}

                <div className='contact_title card-title' id="faq">
                    <div className='faq_header'>
                        <div>FAQ</div>
                        {this.state.invest ? this.state.invest.length !== 0 ? <div className='faq_button2'>
                            <div onClick={() => { this.toggle('1'); }} className={this.state.activeTab === '1' ? 'faq_button2_active' : 'faq_button2_inactive'}>Investor</div>
                            <div onClick={() => { this.toggle('2'); }} className={this.state.activeTab === '2' ? 'faq_button2_active' : 'faq_button2_inactive'}>Business</div>
                        </div> : '' : ''}
                    </div>
                </div>
                <div className='products_div' style={{ display: this.state.activeTab === '2' ? '' : 'none' }}>
                    <Accordion>
                        {this.state.business ? this.state.business.map((invest, index) => {
                            return (
                                <AccordionItem key={index} style={{ borderBottom: index === this.state.business.length - 1 ? 'none' : '' }}>
                                    <AccordionItemTitle className='accordion__title'>
                                        <div className='sub'>
                                            <div className='card-title'>{invest.attributes.title}</div>
                                            <div className="accordion__arrow" role="presentation"></div>
                                        </div>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {ReactHtmlParser(invest.attributes.field_answer_description_.value)}
                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        })
                            : ''}

                    </Accordion>

                </div>

                <div className='products_div' style={{ display: this.state.activeTab === '1' ? '' : 'none' }}>
                    <Accordion>
                        {this.state.invest ? this.state.invest.length !== 0 ? this.state.invest.map((invest, index) => {
                            return (
                                <AccordionItem style={{ borderBottom: index === this.state.invest.length - 1 ? 'none' : '' }} expanded={index === 0 ? true : false}>
                                    <AccordionItemTitle className='accordion__title'>
                                        <div className='sub'>
                                            <div className='card-title'>{invest.attributes.title}</div>
                                            <div class="accordion__arrow" role="presentation"></div>
                                        </div>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {ReactHtmlParser(invest.attributes.field_answer_description_.value)}
                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                        })
                            : '' : ''}
                    </Accordion>
                </div>

                <div >
                    <Footer />
                </div>
            </div>
        )


    }

}

export default Products;





