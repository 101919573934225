import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import OtherDetails from "../styles/other-details";
import cachios from "cachios";
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Loader from "../styles/loader";
import calculateReadingTime from "../styles/readtime";


class SingleBlog extends Component {
    constructor() {
        super()
        this.state = {
            data: null
        }
        this.updateData = this.updateData.bind(this)
    }
    componentDidMount() {
        let path = window.location.pathname
        cachios.get(`${BASEURL}/jsonapi/node/blog?include=field_image,field_category&filter[field_path_alias]=${path}`)
            .then(resp => {
                console.log("single blog", resp.data.data)
                if (resp.data.data.length) {
                    let data = resp.data.data[0], included = resp.data.included
                    let date = new Date(data.attributes.field_date)
                    this.setState({
                        data: resp.data.data[0],
                        published: date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" }),
                        image: data.relationships.field_image.data ? included?.find(x => x.id === data.relationships.field_image.data.id)?.attributes.uri.url : "",
                        category: data.relationships.field_category.data ? included?.find(x => x.id === data.relationships.field_category.data.id)?.attributes.name : ""
                    })
                }
            })
            .catch(err => {
                console.error("single blog error", err)
            })
    }
    updateData() {
        this.setState({
            formvalid: true,
            formvalidtext: 'Link copied to clipboard!',
            formsuccess: true
        });
        setTimeout(
            function () {
                this.setState({ formvalid: false, form2: false });
            }
                .bind(this),
            3000
        );
    }
    render() {
        return (
            <div id="single_blog">
                <Header />
                {this.state.data ? <div className="blog-page pt-0 single_blog-page">
                    <div className="breadcrumbs d-flex">
                        <p className="teritiary-text text-sm-regular mb-0"><Link className="teritiary-text" to="/blog">Blog</Link></p>
                        <img src="/assets/bread-right.png" />
                        <p className="text-sm-regular mb-0 orange-text">{this.state.data.attributes.title}</p>
                    </div>
                    <div className="header-text-banner align-items-start">
                        {/* <div className="read-type">
                            <p className="type text-xs-medium orange-text mb-0">{this.state.category}</p>
                            <p className="time text-xs-medium orange-text mb-0">{isNaN(calculateReadingTime(this.state.data.attributes.body?.value)) ? 0 : calculateReadingTime(this.state.data.attributes.body?.value)} min read</p>
                        </div> */}
                        <h4 className="display-lg-medium primary-text">
                            {this.state.data.attributes.title}
                        </h4>
                        <p className="text-xl-regular teritiary-text">{this.state.data.attributes.field_short_description ? HtmlParser(this.state.data.attributes.field_short_description.value) : ""}</p>
                    </div>
                    <div>
                        {this.state.image && <img className="w-100" src={BASEURL + this.state.image} />}
                        <div className="image-details d-flex justify-content-between mt-4 pt-2">
                            <div className="written-published d-flex">
                                {this.state.data.attributes.field_author_name && <div>
                                    <p className="text-sm-semibold orange-text">Written by</p>
                                    <p className="text-lg-medium primary-text">{this.state.data.attributes.field_author_name ? this.state.data.attributes.field_author_name.value : ''}</p>
                                </div>}
                                <div>
                                    <p className="text-sm-semibold orange-text">Published on</p>
                                    <p className="text-lg-medium primary-text">{this.state.published}</p>
                                </div>
                            </div>
                            <OtherDetails title={this.state.data.attributes.title} updateData={this.updateData} />
                        </div>
                        <div className="main-description mx-auto mb-4">
                            {this.state.data.attributes.body ? HtmlParser(this.state.data.attributes.body.value.replace(/src="\/sites/g, `src="${BASEURL}/sites`)) : ""}
                            <div className="mt-4">
                                <OtherDetails title={this.state.data.attributes.title} updateData={this.updateData} />
                            </div>
                        </div>
                    </div>
                </div> : <div className="newContainer" style={{ minHeight: "62vh" }} > <Loader type="content" /></div>}
                <Footer />

            </div>
        )
    }
}
export default SingleBlog