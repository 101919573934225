import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Axios from 'axios';
import cachios from 'cachios'
import { BASEURL, BASE } from "../api/api";
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
import Header from '../header/header'
import Footer from '../footer/footer'
import './about.css'
import dummy from '../assets/dummyimg.jpg'
import loader from '../assets/loading.gif'
let imagearray = []

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            rerlimg: '',
            list: '',
            proposed: '',
            completed: '',
            team: '',
            testimg: false,
            ellipse: true,
            ind: -1,
            ind2: -1,
            ind3: 0,
            rerlhead: '',
            scroll: props.match.params.id,
            slidercomp: []
        }

    }
    componentWillReceiveProps(nextProps) {

        this.scroll(nextProps.match.params.id)

    }
    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }



    popup(index) {
        document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
        this.setState({
            ind3: index,
            popup: true
        })

    }

    close() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";

        this.setState({
            popup: false
        })
    }

    truncate(index, a) {
        if (a === 1)
            this.setState({
                ind: index
            })
        else
            this.setState({
                ind2: index
            })

    }

    componentWillMount() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
        //foundation
        Axios(BASEURL + '/jsonapi/block_content/description?filter[info]=Rehbar Foundation', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((data) => {
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }

                this.setState({
                    foundation: data.data.data[0].attributes.field_description[0].processed
                })

            })
            .catch((err) => {
                console.log('not found')
            })

        //sliderCompleted



        Axios(BASEURL + '/jsonapi/node/deals?filter[field_status]=completed&include=field_logo', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((data) => {

                this.setState({
                    slidercomp: data.data.included
                })
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })


        //intro

        cachios.get(BASEURL + '/jsonapi/block_content/description', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let i = 0;
                for (i = 0; i < data.data.data.length; i++) {
                    if (data.data.data[i].attributes.info === "Who We Are") {

                        this.setState({
                            intro: data.data.data[i]
                        })
                    }
                }
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })

        //team
        cachios.get(BASEURL + '/jsonapi/node/team?sort=field_number_for_arrangement&include=field_dp', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }
                let i, j;
                this.setState({
                    team: data.data.data,
                    testlen: data.data.data.length,
                    testimg: data.data.included
                })
                this.setState({
                    testimg: true
                })


                for (i = 0; i < data.data.data.length; i++) {

                    if (data.data.data[i].relationships.field_dp.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_dp.data.id === data.data.included[j].id) {
                                imagearray.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        imagearray.push(dummy)
                    }

                }
                this.setState({
                    imagearray: imagearray
                })

                if (imagearray.length === data.data.data.length) {
                    this.setState({
                        testimg: true
                    })
                }




            })

            .catch((err) => {
                console.log('not found')
            })


        //rerlimage
        cachios.get(BASEURL + '/jsonapi/block_content/title_image_image_mob_?include=field_image,field_image_mob', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                if (this.state.width > 767) {
                    this.setState({
                        rerlhead: data.data.data[0].attributes.info,
                        rerlimg: BASE + data.data.included[0].attributes.uri.url
                    })
                }
                else {
                    this.setState({
                        rerlhead: data.data.data[0].attributes.info,
                        rerlimg: BASE + data.data.included[1].attributes.uri.url
                    })
                }
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })


        //lists
        Axios(BASEURL + '/jsonapi/block_content/title_description', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((data) => {
                this.setState({
                    list: data.data.data
                })
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }
            })
            .catch((err) => {
                console.log('not found')
            })





    }
    scroll(pos) {
        const element = document.getElementById(pos);

        if (element) {
            let topPos = element.offsetTop;
            document.body.scrollTop = topPos; // For Safari
            document.documentElement.scrollTop = topPos;
        }
    }


    render() {
        const settings = {
            appendDots: (dots) => {
                if (this.state.width < 768)
                    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
                else
                    return <MagicSliderDots dots={dots} numDotsToShow={this.state.slidercomp.length} dotWidth={30} />
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.slidercomp ? this.state.slidercomp.length < 3 ? this.state.slidercomp.length : this.state.width > 700 ? 4 : 2 : 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500
        };
        return (

            <div className='product_page about_page'>
                <div style={{ display: this.state.popup ? 'flex' : 'none' }} className='overlay'></div>


                <div>
                    <Header />
                </div>
                <div id="who-we-are" className='contact_title card-title'>
                    Who We Are
                </div>


                {/* Introduction */}
                {this.state.intro ?
                    <div className='intro'>
                        <div className='intro_top'>
                            <div className='intro_list'>
                                <div className='bullets'>
                                    {ReactHtmlParser(this.state.intro ? this.state.intro.attributes.field_description[0].processed : '')}
                                </div>
                            </div>
                        </div>

                    </div> : <div className='siteloading'><img src={loader} alt='hometitle' /> </div>}

                {/* RERL */}{this.state.rerlhead ? <div className='about_padding'>
                    <div className='rerl' id="how-we-works">
                        <div className='products_head card-title'>{this.state.rerlhead ? this.state.rerlhead : ''}</div>

                        <img src={this.state.rerlimg} alt='rerl' />

                    </div>
                    <div className="note">Note: RERL does not offer to sell debentures to the public. Issue of debentures is done purely on a private placement basis.</div>
                </div> : ''}


                {/* Management_team */}
                <div className='about_management' id="our-team">

                    <div className='products_head card-title'>The Team</div>
                    <div>
                        Here are the people steering Rehbar to Success
                    </div>
                    <div className='about_card_div'>
                        {this.state.team && this.state.testimg ? this.state.team.map((team, index) => {
                            return (
                                <div key={index} className={team.attributes.field_description ? team.attributes.field_description.value.length > 185 ? 'about_card about_pad' : 'about_card' : 'about_card'}>
                                    <div className='testimonial_header'>
                                        <div className='testimonial_header_div1'>
                                            <div>
                                                <img src={this.state.imagearray ? this.state.imagearray[index] : dummy} height='120px' alt='hometitle' />
                                            </div>
                                            <div style={{ width: '60%' }} className='profile'>
                                                <div className='card-title'>{team.attributes.title}</div>
                                                <div className="card-text">{team.attributes.field_designation.value}</div>
                                            </div>
                                        </div>

                                    </div>
                                    {team.attributes.field_description ? <div className={team.attributes.field_description.value.length > 50 ? this.state.ind3 === index ? 'about_card_text truncate_contact' : 'about_card_text truncate_contact' : 'about_card_text truncate_contact'}>
                                        {ReactHtmlParser(team.attributes.field_description.value)}
                                    </div> : ''}
                                    <p className="cursor about_readmore" onClick={() => this.popup(index)} style={{ marginBottom: team.attributes.field_description.value.length > 185 ? '5%' : '', display: team.attributes.field_description.value.length > 185 ? '' : 'none', color: '#EF3E23' }}>read more</p>

                                </div>)
                        }) : ''}

                    </div>

                    {/* slider */}
                    <div className='products_head card-title clients-title' id="some-of-our-clients" >Some of our Clients</div>
                    <div className='clients mt-0'>
                        <Slider {...settings} >
                            {this.state.slidercomp ? this.state.slidercomp.map((slider, index) => {
                                return (
                                    <div key={index} className='deals_carousel'>
                                        <img src={BASE + slider.attributes.uri.url} alt='img' />
                                    </div>)
                            }) : ''}


                        </Slider>


                    </div>





                </div>
                <div >
                    <Footer />
                </div>
            </div>
        )


    }

}

export default About;





