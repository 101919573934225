import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import ReactHtmlParser from "react-html-parser";
import loader from '../assets/loading.gif'
import Axios from "axios";
import { BASEURL } from "../api/api";

class Foundation extends Component {
    constructor() {
        super()
        this.state = {
            foundation: null
        }
    }
    scroll(pos) {
        const element = document.getElementById(pos);

        if (element) {
            let topPos = element.offsetTop;
            document.body.scrollTop = topPos; // For Safari
            document.documentElement.scrollTop = topPos;
        }
    }
    componentDidMount() {
        Axios(BASEURL + '/jsonapi/block_content/description?filter[info]=Rehbar Foundation', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((data) => {
                if (this.state.rerlhead && this.state.rerlimg && this.state.list && this.state.team && this.state.intro && this.state.slidercomp) {
                    this.scroll(this.state.scroll)
                }

                this.setState({
                    foundation: data.data.data[0].attributes.field_description[0].processed
                })

            })
            .catch((err) => {
                console.log('not found')
            })
    }
    render() {
        return (
            <div>
                <Header />
                {this.state.foundation ? <div className='foundation' id="foundation">
                    <div className='products_head card-title'>Rehbar Foundation</div>
                    <div>
                        {ReactHtmlParser(this.state.foundation ? this.state.foundation : '')}
                    </div>
                </div>
                    : <div style={{ minHeight: "547px" }}>
                        <div className='siteloading' ><img src={loader} alt='hometitle' /> </div>
                    </div>
                }
                <Footer />
            </div>
        )
    }
}
export default Foundation;
