import React, { Component } from "react";
import "./blog.css"
import Header from "../header/header";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import Cachios from "cachios";
import Axios from "axios";
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser";
import ReactPaginate from "react-paginate"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import loader from '../assets/loading.gif'
import calculateReadingTime from "../styles/readtime";
import Loader from "../styles/loader"



class Blog extends Component {
    constructor() {
        super()
        this.state = {
            blog: null,
            blogInlcuded: [],
            categories: null,
            category: "View all",
            offset: 0,
            page: 0,
            blogCount: 0,
            limit: 8,
            date: ""
        }
        this.getBlogs = this.getBlogs.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
    }
    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.get("type")) {
            console.log("search is", searchParams.get("type"))
            this.setState({ category: searchParams.get("type") }, () => {
                this.getBlogs(0)
            })
        }
        else {

            this.getBlogs(0)
        }
        Axios.get(`${BASEURL}/jsonapi/taxonomy_term/blog_category`)
            .then(resp => {
                this.setState({
                    categories: resp.data.data
                })
            })
            .catch(err => {
                console.error("error on blog categories", err)
            })
        Axios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[field_title.value]=Blog`)
            .then(resp => {
                this.setState({
                    banner: resp.data.data[0]
                })
            })
            .catch(err => {
                console.error("error on blog banner", err)
            })
    }
    getBlogs(offset, dateRange) {
        window.scrollTo(0, 0)
        let filter = this.state.category !== "View all" ? this.state.category : ""
        Axios.get(`${BASEURL}/jsonapi/node/blog?filter[field_blog_type]=Blog Post&include=field_image,field_category&sort[sort-date][path]=field_date&sort[sort-date][direction]=DESC&sort[sort-image][path]=field_image.id&sort[sort-image][direction]=DESC${filter && `&filter[field_category.name]=${filter}`}${dateRange ? `&filter[date][condition][path]=field_date&filter[date][condition][operator]=>&filter[date][condition][value]=${dateRange.first}&filter[dateEnd][condition][path]=field_date&filter[dateEnd][condition][operator]=<&filter[dateEnd][condition][value]=${dateRange.last}` : ""}&page[offset]=${offset}&page[limit]=${this.state.limit}`)
            .then(resp => {
                this.setState({
                    blog: resp.data.data,
                    blogInlcuded: resp.data.included,
                    blogCount: Math.ceil(resp.data.meta?.count / this.state.limit)
                })
            })
            .catch(err => {
                console.error("error on blog listing", err)
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.category !== this.state.category) {
            this.setState({ offset: 0, blog: null },
                () => {
                    this.getBlogs(0)
                })
        }
        if (prevState.page !== this.state.page) {
            let offset = this.state.page == 0 ? 0 : (this.state.page * this.state.limit)
            this.setState({ blog: null },
                () => {
                    this.getBlogs(offset)
                })
        }
        if (prevProps.location.search !== this.props.location.search) {
            console.log("changed")
            let searchParams = new URLSearchParams(this.props.location.search)
            if (searchParams.get("type")) {
                this.setState({
                    category: searchParams.get("type")
                })
            }
        }
    }
    handlePageClick(e) {
        console.log("e is ", e)
        this.setState({
            page: e.nextSelectedPage
        })
    }
    handleDateChange(date) {
        if (date) {
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            console.log("selected date is:", firstDayOfMonth.getMinutes(), "last", lastDayOfMonth.getMinutes())
            let dateRange = { first: `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1) < 10 ? `0${firstDayOfMonth.getMonth() + 1}` : firstDayOfMonth.getMonth() + 1}-01}`, last: `${lastDayOfMonth.getFullYear()}-${(lastDayOfMonth.getMonth() + 1) < 10 ? `0${lastDayOfMonth.getMonth() + 1}` : lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate()}` }
            this.setState({
                date: date
            }, () => {
                this.getBlogs(0, dateRange)
            })
        }
        else {
            this.setState({
                date: date
            }, () => {
                this.getBlogs(0)
            })
        }
    }
    render() {
        return (<div id="blog-page">
            <Header />
            <div className="blog-page">
                <div className="text-at-right ml-auto">
                    <div className="header-text-banner mx-auto">
                        {this.state.banner ? <>
                            {/* <p className="sub-head orange-text text-center">{this.state.banner.attributes.field_title?.value}</p> */}
                            <h2 className="heading black-text text-center">{this.state.banner.attributes.info}</h2>
                            <p className="support-text teritiary-text text-center mb-0">{this.state.banner.attributes.field_description.length ? HtmlParser(this.state.banner.attributes.field_description[0].value) : ""}</p>
                        </>
                            : <Loader type="content" />}
                    </div>
                </div>
                <div className="d-flex blog-content-container">
                    <div className="filters">
                        {/* <DatePicker
                            selected={this.state.date}
                            onChange={this.handleDateChange}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select date"
                            className="date-select mb-4 w-100"
                            isClearable={this.state.date}
                            clearButtonClassName="clearbutton"
                            icon={<img width={"15px"} height={"16px"} className="mx-auto" src="/assets/date.png" />}
                            showIcon
                        /> */}
                        {/* <select className="date-select mb-4" onChange={() => this.handleDateChange()}>
                            <option>Select dates</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                        </select> */}
                        <div className="w-100">
                            <p className="text-sm-medium orange-text mb-3 pb-1">Blog categories</p>
                            <div className="categories d-sm-block d-none">
                                <p className={`category text-md-semibold ${this.state.category === "View all" ? "selected" : ""}`} onClick={() => this.setState({ category: "View all" })}>View all</p>
                                {this.state.categories ? this.state.categories.map(category => {
                                    return <p className={`category text-md-semibold ${this.state.category === category.attributes.name ? "selected" : ""}`} onClick={() => this.setState({ category: category.attributes.name })}>{category.attributes.name}</p>
                                }) : <Loader type="content" />}
                            </div>
                            <select className="tab-select-box d-block d-sm-none text-md-medium w-100 mb-4" onChange={(e) => this.setState({ category: e.target.value })} style={{ backgroundImage: "url(/assets/select-down-arrow.png)" }}>
                                <option class="text-md-medium primary-text" value="View all">View All</option>
                                {this.state.categories?.map(category => {
                                    return <option className="category text-md-semibold " value={category.attributes.name} >{category.attributes.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="contents">
                        <div className="blog-list  d-flex flex-wrap">
                            {this.state.blog?.map((single, ind) => {
                                let path = single.attributes.field_path_alias,
                                    category = single.relationships.field_category.data ? this.state.blogInlcuded?.find(x => x.id === single.relationships.field_category.data.id)?.attributes.name : "",
                                    image = single.relationships.field_image.data ? this.state.blogInlcuded?.find(x => x.id === single.relationships.field_image.data.id)?.attributes.uri.url : "",
                                    date = new Date(single.attributes.field_date)
                                let preferedDate = date.toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })
                                return <div className="blog-details col-sm-6 col-12" key={ind}>
                                    <Link to={path}><img className="blog-image w-100 mb-3 pb-1" src={image ? BASEURL + image : "/assets/noimage.png"} /></Link>
                                    <div className="read-panel d-flex align-items-center">
                                        <p className="subject-time px-1">
                                            {category ?
                                                <span className="subject">
                                                    <span className="record-box"><span className="butn"></span></span>
                                                    <span className="subject-name">{category}</span>
                                                </span>
                                                : ""}
                                            <span className="minutes">{isNaN(calculateReadingTime(single.attributes.body?.value)) ? 0 : calculateReadingTime(single.attributes.body?.value)} min read </span>
                                        </p>
                                        <p className="date text-sm-regular teritiary-text">{preferedDate}</p>
                                    </div>
                                    <Link to={path}><div className="d-flex justify-content-between align-items-center arrow-link">
                                        <h4 className="display-xs-semibold primary-text">{single.attributes.title}</h4>
                                        <Link to={path}><img width="10px" height="10px" src="/assets/blog/top-right-arrow.png" /></Link>
                                    </div></Link>
                                    <p className="teritiary-text text-md-regular lineclamp-two">
                                        {single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}
                                    </p>
                                </div>
                            })}
                            {this.state.blog ? this.state.blog.length == 0 ? <div className="no-data w-100 d-flex justify-content-center">No Blogs found</div> : "" : <Loader type="carousel" column="2" />}
                        </div>
                        {this.state.blogCount > 1 ? <> <div className="pagination-1 d-sm-block d-none">
                            <ReactPaginate
                                nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                                // onPageChange={this.handlePageClick}
                                onClick={this.handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={this.state.blogCount}
                                previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item arrow previous-arrow"
                                previousLinkClassName="page-link arrow"
                                nextClassName="page-item arrow next-arrow"
                                nextLinkClassName="page-link arrow"
                                breakLabel="..."
                                breakClassName="dots"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-between"
                                activeClassName="active"
                                activeLinkClassName="activePage"
                                renderOnZeroPageCount={null}
                                forcePage={this.state.page}
                            />
                        </div>
                            <div className="mobile-pagination d-flex d-sm-none">
                                <img src="/assets/paginate-arrow.png" role={this.state.page !== 0 && "button"} onClick={() => this.state.page !== 0 && this.setState({ page: this.state.page - 1 })} className='page-icon left-icon' />
                                Page {this.state.page + 1} of {this.state.blogCount}
                                <img src="/assets/paginate-arrow.png" role={this.state.page !== this.state.blogCount - 1 && "button"} onClick={() => this.state.page !== this.state.blogCount - 1 && this.setState({ page: this.state.page + 1 })} className='page-icon right-icon r-180deg' />
                            </div>
                        </> : ""}
                    </div>
                </div>
            </div>
            <Footer />
        </div>)
    }
}
export default Blog;